import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ButtonCadastro = () => {
    return (
        <a
            className="bg-red-600 hover:bg-red-700 flex items-center rounded"
            href="#"
            title="Quero Aparecer no Mapa!"
        >
            <span className="bg-red-800 p-2 rounded-l">
                <FontAwesomeIcon width={24} icon={faPlus} />
            </span>
            <span className="px-2">Quero Aparecer no Mapa!</span>
        </a>
    );
};

export default ButtonCadastro;
