import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Banner1 from '../assets/images/banner/banner-1.png';
import Banner2 from '../assets/images/banner/banner-2.png';

const ImageCarousel = () => {
    return (
        <div className="container mx-auto">
            <div className='mx-4'>
                <Carousel
                    showArrows={true}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    showThumbs={false}
                    showStatus={false}
                >
                    <div>
                        <img src={Banner1} alt="Slide 1" className='rounded' />
                        {/* <p className="legend">Descrição do Slide 1</p> */}
                    </div>
                    <div>
                        <img src={Banner2} alt="Slide 2" className='rounded' />
                        {/* <p className="legend">Descrição do Slide 2</p> */}
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default ImageCarousel;
