import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSignup, setShowSignup] = useState(false); // Estado para controlar o cadastro

    const handleSubmit = (e) => {
        e.preventDefault();
        // Lógica de login/cadastro
    };

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="flex-grow flex items-center justify-center">
                    <div className="flex w-full max-w-lg "> {/* Modificação aqui */}
                        {/* Bloco Branco (Login) */}
                        <div className="w-full p-8 bg-white shadow-lg rounded-lg">
                            <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-gray-700">E-mail</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full p-2 border rounded-lg"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Senha</label>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full p-2 border rounded-lg"
                                        required
                                    />
                                </div>
                                <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">
                                    Entrar
                                </button>
                            </form>

                            <div className="mt-6">
                                <div className="text-center mb-4">ou entre com</div>
                                <div className="flex justify-center space-x-4">
                                    <button className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 font-medium text-sm">
                                        <FontAwesomeIcon icon={faGoogle} className='mr-2 pr-2 border-white border-r' />
                                        Login com Google
                                    </button>
                                    <button className="bg-blue-800 text-white p-2 rounded-lg hover:bg-blue-900 font-medium text-sm">
                                        <FontAwesomeIcon icon={faFacebookF} className='mr-2 pr-2 w-4 border-white border-r' />
                                        Login com Facebook
                                    </button>
                                </div>
                            </div>

                            <div className="mt-6 text-center">
                                <p className="text-gray-600">Não tem uma conta?</p>
                                <button
                                    className="text-blue-500 hover:underline"
                                    onClick={() => setShowSignup(!showSignup)} // Troca de estado para exibir cadastro
                                >
                                    Cadastre-se
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Bloco de Cadastro (opcional) */}
                    {showSignup && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto w-1/2">
                                <h2 className="text-2xl font-bold mb-6 text-center">Cadastre-se</h2>
                                <form className="space-y-4">
                                    <div>
                                        <label className="block text-gray-700">Nome</label>
                                        <input type="text" className="w-full p-2 border rounded-lg" required />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">E-mail</label>
                                        <input type="email" className="w-full p-2 border rounded-lg" required />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Senha</label>
                                        <input type="password" className="w-full p-2 border rounded-lg" required />
                                    </div>
                                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">
                                        Cadastrar
                                    </button>
                                </form>
                                <button
                                    className="w-full mt-2 p-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                                    onClick={() => setShowSignup(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Login;
