import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faUtensils, faChair } from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const RestaurantCard = ({ restaurant, openModal }) => {
    const [activeTab, setActiveTab] = useState('availability'); // Estado para controlar a aba ativa
    const daysOfWeek = {
        seg: 'Segunda-feira',
        ter: 'Terça-feira',
        qua: 'Quarta-feira',
        qui: 'Quinta-feira',
        sex: 'Sexta-feira',
        sab: 'Sábado',
        dom: 'Domingo'
    };

    // Função para renderizar as estrelas com base na avaliação
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span key={i} className={i <= rating ? "text-yellow-500" : "text-gray-300"}>
                    &#9733; {/* HTML code for a star */}
                </span>
            );
        }
        return stars;
    };

    return (
        <div className="border p-4 rounded-lg shadow-md hover:shadow-lg transition bg-white grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
                {/* Substitua a imagem pelo carrossel */}
                <Carousel
                    showArrows={true}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    showThumbs={false}
                    showStatus={true}
                    thumbWidth={80}
                    renderThumbs={() =>
                        restaurant.images.map((img, index) => (
                            <img
                                key={index}
                                src={img}
                                alt={`Thumb ${index + 1}`}
                                style={{ height: '60px', objectFit: 'cover' }} // Define a altura da miniatura
                            />
                        ))
                    }
                >
                    {restaurant.images.map((img, index) => (
                        <div key={index}>
                            <img src={img} alt={`${restaurant.name} imagem ${index + 1}`} className="w-full h-52 object-cover rounded-md" />
                        </div>
                    ))}
                </Carousel>

                <h3 className="mt-2 text-lg font-semibold">{restaurant.name}</h3>
                <div className="text-yellow-500">{renderStars(restaurant.rating)}</div>
                <div className='mt-3'>
                    {restaurant.description}
                </div>
            </div>

            <div className='col-span-2'>
                <div className='block md:flex gap-6 text-sm mb-4'>
                    <p><FontAwesomeIcon className='mr-2' icon={faLocationDot} /> {restaurant.address}</p>
                    <p><FontAwesomeIcon className='mr-2' icon={faUtensils} /> {restaurant.type}</p>
                    <p><FontAwesomeIcon className='mr-2' icon={faChair} /> até 4 pessoas por mesa</p>
                </div>

                {/* Tabs */}
                <div className="flex border-b mb-4">
                    <button
                        className={`p-2 ${activeTab === 'availability' ? 'border-b-2 border-gray-900' : ''}`}
                        onClick={() => setActiveTab('availability')}
                    >
                        Horários Disp.
                    </button>
                    <button
                        className={`p-2 ${activeTab === 'reviews' ? 'border-b-2 border-gray-900' : ''}`}
                        onClick={() => setActiveTab('reviews')}
                    >
                        Avaliações
                    </button>
                </div>

                {/* Conteúdo das Tabs */}
                <div className='w-full overflow-auto'>
                    {activeTab === 'availability' && (
                        <div>
                            {Object.keys(restaurant.availability).map(day => (
                                <div key={day} className="mb-2">
                                    <span className="font-semibold">{daysOfWeek[day]}: </span>
                                    {restaurant.availability[day].length > 0 ? (
                                        restaurant.availability[day].map((time, index) => (
                                            <button
                                                key={index}
                                                className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1 text-center me-2 mb-2"
                                                onClick={() => openModal(restaurant, `${daysOfWeek[day]} - ${time}`)}
                                            >
                                                {time}
                                            </button>
                                        ))
                                    ) : (
                                        <span className="text-gray-500">Não disponível</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {activeTab === 'reviews' && (
                        <div>
                            <p className="text-gray-600">Aqui vão as avaliações dos usuários...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RestaurantCard;
