import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import RestaurantCard from '../components/RestaurantCard';
import Footer from '../components/Footer';
import restaurants from '../data/restaurantsData';
import ReservationModal from '../components/ReservationModal'; // O modal reutilizável
import ImageCarousel from '../components/ImageCarousel';
import Filtro from '../components/Filtro';

const Region = () => {
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (restaurant, time) => {
        setSelectedRestaurant(restaurant);
        setSelectedTime(time); // Define o horário selecionado
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedRestaurant(null);
        setSelectedTime('');
    };

    return (
        <>
            <Navbar />
            <div className='bg-white py-6'>
                <div className='container flex mx-auto p-4'>
                    <div className='w-1/2'>
                        <h1 className="text-2xl font-bold">Restaurantes da Região</h1>
                    </div>
                </div>
            </div>

            <ImageCarousel />

            <Filtro />

            {/* Lista de Restaurantes */}
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 gap-4">
                    {restaurants.map((restaurant) => (
                        <RestaurantCard
                            key={restaurant.id}
                            restaurant={restaurant}
                            openModal={openModal} // Passa a função para abrir o modal
                        />
                    ))}
                </div>
            </div>

            <Footer />

            {/* Modal de Reserva */}
            {isModalOpen && selectedRestaurant && (
                <ReservationModal
                    restaurant={selectedRestaurant}
                    selectedTime={selectedTime} // Passa o horário selecionado
                    closeModal={closeModal}
                />
            )}
        </>
    );
};

export default Region;
