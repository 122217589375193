import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';

const BaixeApp = () => {
    return (
    <div className="bg-red-600 py-20 md:py-10 bg-no-repeat mt-4" style={{
    backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="%23F3F4F6"><path d="M0 0h1000v4H0z"></path><path d="M0 0h1000v80.8S550 100 500 100 0 80.8 0 80.8V0Z" opacity=".2"></path><path d="M0 0h1000v61.6S600 100 500 100 0 61.6 0 61.6V0Z" opacity=".3"></path><path d="M0 0h1000v42.4S650 100 500 100 0 42.4 0 42.4V0Z" opacity=".4"></path><path d="M0 0h1000v23.2S700 100 500 100 0 23.2 0 23.2V0Z" opacity=".5"></path><path d="M0 0v4s250 96 500 96 500-96 500-96V0H0Z"></path></svg>')`,}}>
            <div className='container mx-auto mt-10 md:mt-44 xl:mt-56 2xl:mt-80'>
                <div className='w-full px-4'>
                    <h2 className=' text-2xl md:text-3xl text-center text-white'>Facilite sua vida com o nosso app! Reserve mesas, descubra novos restaurantes e aproveite ofertas exclusivas na palma da sua mão. Baixe o app agora e transforme sua experiência gastronômica!</h2>
                </div>
                <div className='flex gap-4 justify-center text-white mt-10'>
                    <div className='flex gap-3 items-center bg-black px-3 py-1 rounded hover:bg-white hover:text-black cursor-pointer'>
                        <div>
                        <FontAwesomeIcon icon={faGooglePlay} size='2x' />
                        </div>
                        <div>
                            <p>Disponivel no</p>
                            <p>Google Play</p>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center bg-black px-3 py-1 rounded hover:bg-white hover:text-black cursor-pointer'>
                        <div>
                        <FontAwesomeIcon icon={faApple} size='2x' />
                        </div>
                        <div>
                            <p>Disponivel na</p>
                            <p>Apple Store</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaixeApp;
