import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Importar useNavigate e useLocation
import ButtonCadastro from '../components/ButtonCadastro';
import Logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuClick = () => {
        setIsMenuOpen(false);
    };

    // Função para scroll com offset
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const offset = 100; // Defina um offset para evitar que o scroll fique rente ao topo
            const elementPosition = section.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: window.scrollY + offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleNavigationAndScroll = (sectionId) => {
        if (location.pathname !== '/') {
            // Se não está na home, redirecione para a home e depois faça o scroll
            navigate('/');
            setTimeout(() => scrollToSection(sectionId), 300); // Pequeno delay para garantir que o DOM carregue
        } else {
            // Se já está na home, apenas faça o scroll suave
            scrollToSection(sectionId);
        }
    };

    return (
        <nav className="text-white font-bold">
            <div className='bg-gray-900 px-4'>
                <div className="container mx-auto flex sm:items-center justify-between gap-10">
                    <a href='/'>
                        <div className="logo bg-red-900 px-1 flex items-center">
                            <div className="bg-logoRed logo px-4 h-full flex items-center max-sm:h-14 sm:h-14">
                                <img src={Logo} title="Primera" className="h-8 mr-2" />
                                <h5>Primera Mesa</h5>
                            </div>
                        </div>
                    </a>

                    <ul className="hidden md:flex space-x-4 py-4 grow">
                        <li>
                            <a 
                                onClick={() => handleNavigationAndScroll('como-funciona')} 
                                className="hover:text-yellow-500 cursor-pointer"
                            >
                                Como Funciona
                            </a>
                        </li>
                        <li>
                            <a 
                                onClick={() => handleNavigationAndScroll('depoimentos')} 
                                className="hover:text-yellow-500 cursor-pointer"
                            >
                                Depoimentos
                            </a>
                        </li>
                    </ul>

                    <div className="items-center hidden md:flex">
                        <ButtonCadastro />
                    </div>

                    <div className='flex'>
                        <div className="items-center flex mr-6">
                            <a href='/login'>
                            <FontAwesomeIcon icon={faCircleUser} style={{fontSize: '30'}}/>
                            </a>
                        </div>

                        <div className="flex md:hidden items-center">
                            <button onClick={toggleMenu} className="text-white focus:outline-none transition duration-300">
                                {isMenuOpen ? (
                                    <FontAwesomeIcon icon={faXmark} size='2x' />
                                ) : (
                                    <FontAwesomeIcon icon={faBars} size='2x' />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isMenuOpen && (
                <div className='flex justify-end absolute right-0 w-8/12 sm:w-1/2 z-10'>
                    <ul className="md:hidden flex flex-col space-y-4 m-1.5 p-4 bg-gray-800 rounded w-full text-center">
                        <li>
                            <a 
                                onClick={() => { handleNavigationAndScroll('como-funciona'); handleMenuClick(); }} 
                                className="hover:text-yellow-500 cursor-pointer"
                            >
                                Como Funciona
                            </a>
                        </li>
                        <li>
                            <a 
                                onClick={() => { handleNavigationAndScroll('depoimentos'); handleMenuClick(); }} 
                                className="hover:text-yellow-500 cursor-pointer"
                            >
                                Depoimentos
                            </a>
                        </li>
                        <li className="flex justify-center">
                            <ButtonCadastro />
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
