import cidade1 from '../assets/images/cidades/cidade-1.jpg';
import cidade2 from '../assets/images/cidades/cidade-2.jpg';
import cidade3 from '../assets/images/cidades/cidade-3.jpg';
import cidade4 from '../assets/images/cidades/cidade-4.jpg';
import cidade5 from '../assets/images/cidades/cidade-5.jpg';

const regions = [
    { id: 1, name: 'Centro', img: cidade1 },
    { id: 2, name: 'Vila Arens', img: cidade2 },
    { id: 3, name: 'Anhangabaú', img: cidade3 },
    { id: 4, name: 'Vila Hortolândia', img: cidade4 },
    { id: 5, name: 'Jardim Bonfiglioli', img: cidade5 },
    { id: 6, name: 'Jardim Samambaia', img: cidade1 },
];

export default regions;