import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

const ComoFunciona = () => {
    return (
        <div className="bg-gray-100 content-center mt-8 mx-2 md:mx-0" id="como-funciona">
            <div className="flex-row md:flex container mx-auto bg-white rounded-3xl pl-3 shadow p-6 md:p-0">
                <div className="w-full md:w-1/2 lg:pl-2 flex-row content-center">
                    <h2 className="text-4xl font-bold mb-10">Como Funciona?</h2>
                    <ul>
                        <li className="mb-4 text-lg flex gap-2">
                            <FontAwesomeIcon icon={faCircleCheck} className='text-red-600' size='2x'/>
                            <p className='content-center'>Escolha o restaurante, o horário e faça sua reserva para 2, 4 ou 6 pessoas.</p>
                        </li>
                        <li className="mb-4 text-lg flex gap-2">
                            <FontAwesomeIcon icon={faCircleCheck} className='text-red-600' size='2x'/>
                            <p className='content-center'>Todos os participantes ganham 50% de desconto no cardápio (exceto bebidas).</p>
                        </li>
                        <li className="mb-4 text-lg flex gap-2">
                            <FontAwesomeIcon icon={faCircleCheck} className='text-red-600' size='2x'/>
                            <p className='content-center'>Pronto! Basta chegar no horário e aproveitar uma experiência gastronômica única.</p>
                        </li>
                    </ul>
                </div>

                <div className="w-full md:w-1/2 flex justify-end mt-8 md:mt-0">
                    <img src="https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-14.png" title="Restaurante" className="md:max-h-96 rounded-full md:rounded-3xl" />
                </div>
            </div>
        </div>
    );
};
export default ComoFunciona;