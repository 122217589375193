import React, { useEffect, useState } from 'react';

const Depoimentos = () => {
    const [index, setIndex] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(3); // Inicia com 3 itens por vez (padrão para telas grandes)

    const depoimentos = [
        {
            text: '"A experiência foi incrível! A plataforma facilitou encontrar o restaurante perfeito na minha cidade, e a reserva foi super rápida. Além disso, o atendimento do restaurante foi impecável. Com certeza vou usar esse serviço de novo!"',
            name: 'Mariana Costa'
        },
        {
            text: '"Eu estava procurando um restaurante especial para uma comemoração e encontrei facilmente usando o mapa interativo. A reserva online foi super prática e quando chegamos, tudo estava pronto. Ótima experiência!"',
            name: 'Carlos Souza'
        },
        {
            text: '"Nunca foi tão fácil encontrar restaurantes na minha cidade! O mapa interativo é muito intuitivo, e o sistema de reserva funciona perfeitamente. Com certeza vou recomendar para amigos e familiares!"',
            name: 'Fernanda Lima'
        },
        {
            text: '"A plataforma é fantástica! Reservamos uma mesa em poucos minutos e a experiência no restaurante foi ainda melhor do que esperávamos. Esse serviço realmente ajuda a economizar tempo e garantir uma boa refeição!"',
            name: 'João Ribeiro'
        },
        {
            text: '"O serviço é excelente e super prático. Em questão de minutos, já tinha a minha reserva confirmada e pude aproveitar uma noite incrível com minha família!"',
            name: 'Ana Clara'
        },
        {
            text: '"Achei o processo muito eficiente e a plataforma me ajudou a encontrar o restaurante ideal para um jantar de negócios. Super recomendo!"',
            name: 'Pedro Fernandes'
        }
    ];

    // Ajustar a quantidade de itens por vez com base no tamanho da janela
    useEffect(() => {
        const updateItemsPerView = () => {
            if (window.innerWidth < 768) {
                setItemsPerView(1); // 2 itens por vez em telas menores que 768px
            } else {
                setItemsPerView(3); // 3 itens por vez em telas maiores
            }
        };

        updateItemsPerView(); // Chama quando o componente é montado
        window.addEventListener('resize', updateItemsPerView); // Ajusta ao redimensionar a janela

        return () => {
            window.removeEventListener('resize', updateItemsPerView);
        };
    }, []);

    // Função para mudar os depoimentos a cada 3 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % (depoimentos.length - itemsPerView + 1)); // Move um por vez
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [depoimentos.length, itemsPerView]);

    return (
        <div className="overflow-hidden relative h-[90] md:h-[100] container mx-auto mt-10" id='depoimentos'>
            <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${index * (100 / itemsPerView)}%)` }}
            >
                {depoimentos.map((depoimento, i) => (
                    <div key={i} className={`w-full md:w-1/${itemsPerView} flex-shrink-0 p-4`}>
                        <div className="bg-white rounded shadow p-5">
                            <p className="text-yellow-500">⭑⭑⭑⭑⭑</p>
                            <p className="mt-3">{depoimento.text}</p>
                            <p className="font-bold my-3">- {depoimento.name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Depoimentos;
