import React, { useState } from 'react';

const ReservationModal = ({ restaurant, selectedTime, closeModal }) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [peopleCount, setPeopleCount] = useState(2); // Default: 2 pessoas

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/2 lg:w-1/3">
                <h2 className="text-2xl font-bold mb-2">{restaurant.name}</h2>
                <p><strong>Endereço:</strong> {restaurant.address}</p>
                <p><strong>Horário Selecionado:</strong> {selectedTime}</p>

                <div className="mt-4">
                    <label className="block font-semibold">Forma de Pagamento:</label>
                    <select className="border p-2 w-full mb-4" onChange={handlePaymentChange}>
                        <option value="">Selecione</option>
                        <option value="cartao">Cartão de Crédito</option>
                        <option value="pix">Pix</option>
                    </select>

                    {paymentMethod === 'pix' && (
                        <div>
                            <div className='justify-center flex'>
                                <img src="https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=https://google.com" alt="QR Code do Pix" className="w-32 h-32" />
                            </div>
                            <p className="font-semibold">Valor: R$ 100,00</p>
                            <div className="mt-2 flex gap">
                                <input
                                    type="text"
                                    value="Copia e cola: 123456789"
                                    readOnly
                                    className="border p-2 w-9/12 rounded-r-none"
                                />
                                <button
                                    className="rounded-l-none bg-blue-500 text-white py-3 px-3 rounded-md w-3/12"
                                    onClick={() => navigator.clipboard.writeText('123456789')}
                                >
                                    Copiar Código
                                </button>
                            </div>
                        </div>
                    )}

                    {paymentMethod === 'cartao' && (
                        <div className="mt-4">
                            <div className="mb-4">
                                <label className="block font-semibold">Número do Cartão:</label>
                                <input
                                    type="text"
                                    placeholder="0000 0000 0000 0000"
                                    className="border p-2 w-full"
                                    maxLength={19}
                                    onKeyPress={(e) => {
                                        const regex = /^[0-9 ]+$/;
                                        if (!regex.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>

                            <div className="flex justify-between gap-4">
                                <div>
                                    <label className="block font-semibold">Validade:</label>
                                    <input
                                        type="text"
                                        placeholder="MM/AA"
                                        className="border p-2 w-full"
                                        maxLength={5}
                                        onKeyPress={(e) => {
                                            const regex = /^[0-9/]+$/;
                                            if (!regex.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    <label className="block font-semibold">CVV:</label>
                                    <input
                                        type="text"
                                        placeholder="123"
                                        className="border p-2 w-full"
                                        maxLength={3}
                                        onKeyPress={(e) => {
                                            const regex = /^[0-9]+$/;
                                            if (!regex.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <label className="block font-semibold">Nome Impresso no Cartão:</label>
                                <input type="text" className="border p-2 w-full" />
                            </div>
                        </div>
                    )}

                    <div className="mt-4">
                        <label className="block font-semibold">Quantidade de Pessoas:</label>
                        <select
                            className="border p-2 w-full"
                            value={peopleCount}
                            onChange={(e) => setPeopleCount(e.target.value)}
                        >
                            <option value={2}>2 Pessoas</option>
                            <option value={3}>3 Pessoas</option>
                            <option value={4}>4 Pessoas</option>
                            <option value={6}>6 Pessoas</option>
                        </select>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <button onClick={closeModal} className="mt-6 bg-red-500 text-white py-2 px-4 rounded">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReservationModal;
