import React from 'react';

const MapRegion = ({ region, onClick, image }) => {
    return (
        <figure className="relative max-w-sm transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0">
            <a href="#" onClick={onClick}>
                <img className="rounded-full lg:h-36 xl:h-52 2xl:h-60 md:h-60 sm:h-72 h-48" src={image} alt={region.name} />
            </a>
            <figcaption className="absolute px-4 text-xl text-center top-1/2 w-full font-bold">
                <h2 className="text-white [text-shadow:_1px_1px_0_rgb(0_0_0)]">{region.name}</h2>
            </figcaption>
        </figure>
    );
};

export default MapRegion;
