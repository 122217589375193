import React from 'react';
import Location from '../assets/images/location.jpg';
import background from '../assets/images/bg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const Destaque = () => {
    return (
        <div className="bg-red-600 relative">
            <div className="z-10 relative container mx-auto flex-row md:flex py-8 gap-10 px-4 md:px-0px-4 md:px-0">
                <div className="w-full md:w-1/3 md:ml-2">
                    <img src={Location} title="Localização Cidade" className='' />
                </div>
                <div className="w-full md:w-2/3 content-center mt-4 md:mt-0">
                    <div>
                        <h1 className="text-white text-2xl text-center md:text-left lg:text-4xl xl:text-5xl font-bold mb-5">Quer fazer parte do nosso mapa?</h1>
                        <h2 className="text-white text-center md:text-left text-lg">Cadastre-se agora e coloque sua localização no radar! Seja encontrado por pessoas da sua região e ajude a criar uma rede mais conectada. Preencha o formulário e faça parte dessa comunidade que cresce a cada dia!</h2>
                    </div>
                    <div className="grid mt-5 justify-items-end">
                        <a className="bg-gray-900 hover:bg-gray-700 flex items-center rounded text-white lg:mr-4" href="#" title="Quero Aparecer no Mapa!">
                            <span className="bg-gray-950 p-2 rounded-l">
                            <FontAwesomeIcon width={24} icon={faPlus} />
                            </span>
                            <span className="px-2">Quero Aparecer no Mapa!</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="z-0 top-0 opacity-35 w-full h-full absolute [mix-blend-mode:multiply] [background-size:cover] [background-repeat:no-repeat] [background-position: top right]" style={{ backgroundImage: `url(${background})` }}></div>
        </div>
    );
};

export default Destaque;
