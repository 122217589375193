import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';  // Para redirecionar para outra página
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons'

// Coordenadas e informações dos restaurantes nas principais cidades do Brasil
import restaurants from '../data/restaurantsData';

// Ajuste do ícone personalizado
const customMarkerIcon = L.divIcon({
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="40" height="40">
      <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd"/>
    </svg>
  `,
    className: 'text-red-600',
    iconSize: [40, 40],  // Tamanho do ícone
    iconAnchor: [20, 5], // Ajuste o ponto de ancoragem para o centro inferior do ícone
});

// Função para renderizar as estrelas com base na avaliação
const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? "text-yellow-500" : "text-gray-300"}>
                &#9733; {/* HTML code for a star */}
            </span>
        );
    }
    return stars;
};

const renderAvailability = (availability) => {
    const days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];
    return days.map(day => (
        <span
            key={day}
            className={`inline-block px-1 capitalize mt-1 py-0.5 rounded-full mr-0.5 text-white ${availability[day] && availability[day].length > 0 ? 'bg-green-600' : 'bg-red-600'}`}
        >
            {day}
        </span>
    ));
};


const MapSearch = () => {
    const [search, setSearch] = useState('');
    const [position, setPosition] = useState([-14.235004, -51.92528]); // Localização default (Brasil)
    const mapRef = useRef();
    const navigate = useNavigate();  // Hook do react-router para navegação

    // Captura a localização do usuário
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    const { latitude, longitude } = pos.coords;
                    setPosition([latitude, longitude]);
                    if (mapRef.current) {
                        mapRef.current.setView([latitude, longitude], 12); // Centraliza o mapa
                    }
                },
                (err) => {
                    console.log('Erro ao obter localização, mostrando mapa do Brasil', err);
                }
            );
        }
    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    // Agrupa restaurantes por cidade e conta quantos existem por cidade
    const cityRestaurantCount = restaurants.reduce((acc, restaurant) => {
        acc[restaurant.city] = (acc[restaurant.city] || 0) + 1;
        return acc;
    }, {});

    // Filtra cidades únicas
    const uniqueCities = Object.keys(cityRestaurantCount);

    const filteredCities = uniqueCities.filter((city) =>
        city.toLowerCase().includes(search.toLowerCase())
    );

    // Função para redirecionar para a página da região
    const redirectToRegion = (cityId) => {
        navigate(`/region/${cityId}`);
    };

    return (
        <div className="w-full h-full">
            <div className="lg:flex flex-row">
                <div className="w-full lg:w-8/12 xl:w-9/12">
                    {/* Mapa com Leaflet */}
                    <MapContainer
                        center={position}
                        zoom={5} // Zoom inicial para mostrar o Brasil
                        style={{ height: '500px', width: '100%', zIndex: '0' }}
                        ref={mapRef}
                        attributionControl={false}
                    >
                        <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                            attribution="&copy; Todos os Direitos Reservados"
                        />

                        {/* Exibir marcadores para os restaurantes */}
                        {restaurants.map((restaurant) => (
                            <Marker key={restaurant.id} position={restaurant.coords} icon={customMarkerIcon}>
                                <Popup maxWidth="230">
                                    <img src={restaurant.img} alt={restaurant.name} className="w-full object-cover rounded pb-2 mt-6 ml-0.5" />
                                    <p className="text-lg !m-0">{restaurant.name}</p>
                                    <p className="text-sm !my-2">Avaliação: {renderStars(restaurant.rating)}</p>
                                    <div className="mt-1">
                                        Disponibilidade:
                                        <div className="mt-1">
                                            {renderAvailability(restaurant.availability)}
                                        </div>
                                    </div>
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
                <div className="w-full lg:w-4/12 xl:w-3/12 p-4">
                    {/* Barra de busca */}
                    <div className="mb-2">
                        <input
                            type="text"
                            placeholder="Buscar cidade"
                            value={search}
                            onChange={handleSearch}
                            className="p-2 border rounded w-full"
                        />
                    </div>

                    {/* Lista de cidades com quantidade de restaurantes */}
                    <div className="mt-2">
                        <h3 className="text-lg font-semibold">Cidades Encontradas:</h3>
                        <ul className="overflow-auto h-96">
                            {filteredCities.map((city, index) => (
                                <li key={index} className="p-2 hover:bg-gray-100 flex justify-between">
                                    <div className='cursor-pointer w-1/2'
                                        onClick={() => {
                                            const cityRestaurant = restaurants.find(restaurant => restaurant.city === city);
                                            if (cityRestaurant) {
                                                mapRef.current.setView(cityRestaurant.coords, 12);
                                            }
                                        }}
                                    >
                                        {city}
                                    </div>

                                    {/* Botão para ver todos os restaurantes da região */}
                                    <button
                                        className="bg-red-500 text-white px-2 text-xs text-center font-medium rounded-full"
                                        onClick={() => redirectToRegion(index)}
                                    >
                                        <FontAwesomeIcon icon={faList} className='mr-2' />
                                        Ver Todos
                                        <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
                                        {cityRestaurantCount[city]}
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapSearch;
