import React from 'react';
import Navbar from '../components/Navbar';
import MapRegion from '../components/MapRegion';
import MapSearch from '../components/MapSearch';
import ComoFunciona from '../components/ComoFunciona';
import Destaque from '../components/Destaque';
import BaixeApp from '../components/BaixeApp';
import Footer from '../components/Footer';
import Depoimentos from '../components/Depoimentos';
import regions from '../data/regioesData';

const Home = () => {
    const handleRegionClick = (region) => {
        window.location.href = `/region/${region.id}`;
    };

    return (
        <>
            <Navbar />
            <div className="bg-white">
                <MapSearch />
            </div>

            <Destaque />
            <ComoFunciona />
            <Depoimentos />

            <div className="container mx-auto p-4">
                <h3 className="text-red-600 text-lg font-bold">Descubra</h3>
                <h1 className="text-4xl font-bold mb-10">Encontre Parceiro na sua Região</h1>
                <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-4">
                    {regions.map((region) => (
                        <MapRegion key={region.id} region={region} image={region.img} onClick={() => handleRegionClick(region)} />
                    ))}
                </div>
            </div>
            
            <BaixeApp />
            <Footer />
        </>
    );
};

export default Home;
