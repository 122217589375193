import React from 'react';
import Logo from '../assets/images/logo.png';

const Footer = () => {
    return (
        <div className="bg-gray-950">
            <div className='flex items-center container mx-auto'>
                <div className='w-44'>
                    <div className="logo bg-red-900 px-1">
                        <div className="bg-logoRed py-2 px-3 logo h-full flex items-center">
                            <img src={Logo} title="Primera" className="h-8 mr-2" />
                            <h5 className='text-white font-bold'>Primera Mesa</h5>
                        </div>
                    </div>
                </div>
                <div className='grow'>
                    <p className='text-white text-center'>&copy; Todos os Direitos Reservados</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
