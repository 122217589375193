import React from 'react';

const Filtro = () => {
    return (
        <div className='container mx-auto mt-2'>
            <div className='bg-white mx-4 rounded p-4'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {/* Campo de busca */}
                    <div>
                        <label htmlFor="search" className="block text-sm font-medium text-gray-700">Buscar</label>
                        <input
                            type="text"
                            id="search"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Nome do restaurante"
                        />
                    </div>

                    {/* Select para tipo de restaurante */}
                    <div>
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Tipo de Restaurante</label>
                        <select
                            id="type"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Todos</option>
                            <option value="italiano">Italiano</option>
                            <option value="brasileiro">Brasileiro</option>
                            <option value="japones">Japonês</option>
                            <option value="mexicano">Mexicano</option>
                            {/* Adicione outros tipos conforme necessário */}
                        </select>
                    </div>

                    {/* Select para disponibilidade de dia */}
                    <div>
                        <label htmlFor="availability" className="block text-sm font-medium text-gray-700">Disponibilidade</label>
                        <select
                            id="availability"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Todos os dias</option>
                            <option value="seg">Segunda-feira</option>
                            <option value="ter">Terça-feira</option>
                            <option value="qua">Quarta-feira</option>
                            <option value="qui">Quinta-feira</option>
                            <option value="sex">Sexta-feira</option>
                            <option value="sab">Sábado</option>
                            <option value="dom">Domingo</option>
                        </select>
                    </div>

                    {/* Select para região */}
                    <div>
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">Região</label>
                        <select
                            id="region"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Todas as regiões</option>
                            <option value="norte">Norte</option>
                            <option value="nordeste">Nordeste</option>
                            <option value="centro-oeste">Centro-Oeste</option>
                            <option value="sudeste">Sudeste</option>
                            <option value="sul">Sul</option>
                            {/* Adicione outras regiões conforme necessário */}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filtro;
