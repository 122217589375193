import restauranteImg from '../assets/images/location.jpg'; // Exemplo de imagem de restaurante

const description = 'Lorem ipsum dolor sit amet. Est veniam mollitia ut velit recusandae et doloribus consequatur ex aperiam voluptatem qui voluptatem quia in numquam repellendus est itaque ducimus';

const horario = {
    seg: ['12:30', '13:00', '17:00', '17:01', '17:02', '17:03', '17:04', '17:05', '17:06', '17:07', '17:08', '17:09', '18:03', '18:04', '18:05', '18:06' ],
    ter: ['12:00', '14:30', '18:00'],
    qua: [],
    qui: ['11:00', '12:30', '19:00'],
    sex: ['10:30', '13:30', '20:00'],
    sab: [],
    dom: []
};

const horario2 = {
    seg: [],
    ter: ['12:00', '14:30', '18:00'],
    qua: ['12:30', '13:00', '17:00', '17:01', '17:02', '17:03'],
    qui: ['11:00', '12:30', '19:00'],
    sex: ['10:30', '13:30', '20:00'],
    sab: [],
    dom: ['12:30', '13:00', '17:00', '17:01', '17:02', '17:03']
};

const address = 'Rua um dois tres, 456 - cidade/sp - cep. 00000-000';
const type = 'Brasileiro';

const restaurants = [
    // Jundiaí-SP
    { id: 1, name: 'Restaurante Jundiaí A', coords: [-23.1856, -46.8978], city: 'Jundiaí', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 2, name: 'Restaurante Jundiaí B', coords: [-23.1850, -46.8880], city: 'Jundiaí', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 3, name: 'Restaurante Jundiaí C', coords: [-23.1880, -46.9000], city: 'Jundiaí', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 4, name: 'Restaurante Jundiaí D', coords: [-23.1870, -46.8930], city: 'Jundiaí', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // São Paulo-SP
    { id: 5, name: 'Restaurante São Paulo A', coords: [-23.55052, -46.633308], city: 'São Paulo', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 6, name: 'Restaurante São Paulo B', coords: [-23.561684, -46.655481], city: 'São Paulo', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 7, name: 'Restaurante São Paulo C', coords: [-23.574327, -46.647319], city: 'São Paulo', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 8, name: 'Restaurante São Paulo D', coords: [-23.543278, -46.640226], city: 'São Paulo', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Rio de Janeiro-RJ
    { id: 9, name: 'Restaurante Rio A', coords: [-22.906846, -43.172896], city: 'Rio de Janeiro', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 10, name: 'Restaurante Rio B', coords: [-22.921095, -43.230182], city: 'Rio de Janeiro', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 11, name: 'Restaurante Rio C', coords: [-22.935565, -43.184792], city: 'Rio de Janeiro', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 12, name: 'Restaurante Rio D', coords: [-22.909930, -43.176652], city: 'Rio de Janeiro', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Belo Horizonte-MG
    { id: 13, name: 'Restaurante BH A', coords: [-19.916681, -43.934493], city: 'Belo Horizonte', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 14, name: 'Restaurante BH B', coords: [-19.927564, -43.938186], city: 'Belo Horizonte', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 15, name: 'Restaurante BH C', coords: [-19.920622, -43.929980], city: 'Belo Horizonte', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 16, name: 'Restaurante BH D', coords: [-19.925365, -43.923712], city: 'Belo Horizonte', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Brasília-DF
    { id: 17, name: 'Restaurante Brasília A', coords: [-15.826691, -47.921821], city: 'Brasília', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 18, name: 'Restaurante Brasília B', coords: [-15.790372, -47.884858], city: 'Brasília', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 19, name: 'Restaurante Brasília C', coords: [-15.836829, -47.929824], city: 'Brasília', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 20, name: 'Restaurante Brasília D', coords: [-15.797580, -47.883645], city: 'Brasília', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Curitiba-PR
    { id: 21, name: 'Restaurante Curitiba A', coords: [-25.4284, -49.2733], city: 'Curitiba', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 22, name: 'Restaurante Curitiba B', coords: [-25.437456, -49.276855], city: 'Curitiba', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 23, name: 'Restaurante Curitiba C', coords: [-25.427718, -49.282870], city: 'Curitiba', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 24, name: 'Restaurante Curitiba D', coords: [-25.433702, -49.258900], city: 'Curitiba', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Salvador-BA
    { id: 25, name: 'Restaurante Salvador A', coords: [-12.9714, -38.5014], city: 'Salvador', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 26, name: 'Restaurante Salvador B', coords: [-12.979562, -38.502144], city: 'Salvador', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 27, name: 'Restaurante Salvador C', coords: [-12.973772, -38.514563], city: 'Salvador', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 28, name: 'Restaurante Salvador D', coords: [-12.961676, -38.497693], city: 'Salvador', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Porto Alegre-RS
    { id: 29, name: 'Restaurante Porto Alegre A', coords: [-30.0346, -51.2177], city: 'Porto Alegre', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 30, name: 'Restaurante Porto Alegre B', coords: [-30.048421, -51.235364], city: 'Porto Alegre', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 31, name: 'Restaurante Porto Alegre C', coords: [-30.029667, -51.218689], city: 'Porto Alegre', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 32, name: 'Restaurante Porto Alegre D', coords: [-30.027178, -51.221183], city: 'Porto Alegre', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Florianópolis-SC
    { id: 33, name: 'Restaurante Florianópolis A', coords: [-27.5954, -48.5480], city: 'Florianópolis', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 34, name: 'Restaurante Florianópolis B', coords: [-27.599652, -48.552655], city: 'Florianópolis', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 35, name: 'Restaurante Florianópolis C', coords: [-27.586936, -48.543280], city: 'Florianópolis', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario2, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 36, name: 'Restaurante Florianópolis D', coords: [-27.593402, -48.555934], city: 'Florianópolis', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Recife-PE
    { id: 37, name: 'Restaurante Recife A', coords: [-8.0476, -34.8770], city: 'Recife', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 38, name: 'Restaurante Recife B', coords: [-8.054999, -34.881111], city: 'Recife', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 39, name: 'Restaurante Recife C', coords: [-8.048873, -34.872706], city: 'Recife', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 40, name: 'Restaurante Recife D', coords: [-8.042089, -34.877993], city: 'Recife', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Fortaleza-CE
    { id: 41, name: 'Restaurante Fortaleza A', coords: [-3.71722, -38.5433], city: 'Fortaleza', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 42, name: 'Restaurante Fortaleza B', coords: [-3.725018, -38.525905], city: 'Fortaleza', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 43, name: 'Restaurante Fortaleza C', coords: [-3.712356, -38.541872], city: 'Fortaleza', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 44, name: 'Restaurante Fortaleza D', coords: [-3.727509, -38.533926], city: 'Fortaleza', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Manaus-AM
    { id: 45, name: 'Restaurante Manaus A', coords: [-3.119027, -60.021731], city: 'Manaus', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 46, name: 'Restaurante Manaus B', coords: [-3.114173, -60.027532], city: 'Manaus', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 47, name: 'Restaurante Manaus C', coords: [-3.106209, -60.028702], city: 'Manaus', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 48, name: 'Restaurante Manaus D', coords: [-3.110195, -60.019982], city: 'Manaus', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},

    // Goiânia-GO
    { id: 49, name: 'Restaurante Goiânia A', coords: [-16.6869, -49.2648], city: 'Goiânia', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type},
    { id: 50, name: 'Restaurante Goiânia B', coords: [-16.695345, -49.269674], city: 'Goiânia', img: restauranteImg, images: [restauranteImg, restauranteImg], rating: 4, description: description, availability: horario, wifi : true, smoking : true, wheelchair : true, dog : true, leaf : true, parking : true, address: address, type: type  }
];

export default restaurants;